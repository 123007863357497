<template>
    <section class="webflow__about">
        <div class="container">
            <div class="webflow__about__title">
                <h1 class="webflow__about__title-header fonts__h1">{{$t('webflow_page.about.title')}}</h1>
                <p class="webflow__about__title-text fonts__p18">{{$t('webflow_page.about.text')}}</p>
            </div>
            <div class="webflow__about__img">
                <img src="../assets/pages/webflow/Webflow_about.png" alt="" loading="lazy" />
            </div>
        </div>
    </section>

    <section class="webflow__why">
        <div class="container">
            <div class="webflow__why__title">
                <h2 class="webflow__why__title-header fonts__h2">{{$t('webflow_page.why.title')}}</h2>
                <p class="webflow__why__title-text fonts__p18">{{$t('webflow_page.why.text')}}</p>
            </div>
        </div>
    </section>

    <section class="webflow__explore">
        <div class="container">
            <div class="webflow__explore__grid">
                <div class="webflow__explore__grid-col1">
                    <h2 class="webflow__explore__title fonts__h2-white">{{$t('webflow_page.explore.title')}}</h2>
                    <img src="../assets/pages/webflow/webflow_explore_col1.png" alt="" loading="lazy" />
                    <p class="webflow__explore__grid-col1-text fonts__p18-white">{{$t('webflow_page.explore.text[0]')}}</p>
                </div>
                <div class="webflow__explore__grid-col2">
                    <p class="webflow__explore__grid-col2-text fonts__p18-white">{{$t('webflow_page.explore.text[1]')}}</p>
                    <img src="../assets/pages/webflow/webflow_explore_col2.png" alt="" loading="lazy" />
                </div>
            </div>
        </div>
    </section>

    <section class="webflow__job">
        <div class="container">
            <div class="webflow__job__grid">
                <div class="webflow__job__grid-col1">
                    <h2 class="webflow__job__title fonts__h2">{{$t('webflow_page.job.title')}}</h2>
                    <p class="webflow__job__grid-col1-text fonts__p18">{{$t('webflow_page.job.text')}}</p>
                    <ButtonWhite 
                       buttonText="webflow_page.job.button"
                       buttonLink="/contacts"
                       class="fonts__p18"
                    />
                </div>
                <div class="webflow__job__grid-col2">
                    <h3 class="webflow__job__sub_title fonts__h3">{{$t('webflow_page.job.sub_title[0]')}}</h3>
                    <p class="webflow__job__grid-col2-text fonts__p18">{{$t('webflow_page.job.sub_text[0]')}}</p>
                    <h3 class="webflow__job__sub_title fonts__h3">{{$t('webflow_page.job.sub_title[1]')}}</h3>
                    <p class="webflow__job__grid-col2-text fonts__p18">{{$t('webflow_page.job.sub_text[1]')}}</p>
                </div>
            </div>
        </div>
    </section>
   <!-- <WebflowTemplates /> -->
    <section class="webflow__webmil">
        <div class="container">
            <h2 class="webflow__webmil__title fonts__h2">{{$t('webflow_page.webmil.title')}}</h2>
           
        </div>
        <div class="webflow__webmil__grid">
            <div class="webflow__webmil__grid-item" v-for="(content, index) in $tm('webflow_page.webmil.sub_themes')" :key="index">
                <h3 class="webflow__webmil__grid-item-title fonts__h3">{{ $rt(content.title) }}</h3>
                <p class="webflow__webmil__grid-item-text fonts__p18">{{ $rt(content.text) }}</p>
            </div>
        </div>
    </section>

    <section class="webflow__contact_us">
        <ContactsNew />
    </section>
</template>

<script>
//import WebflowTemplates from "../components/WebflowTemplates";
import ContactsNew from "../components/ContactsNew";
import ButtonWhite from "../components/buttons/ButtonWhite";
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "Webflow",
  components:{
   // WebflowTemplates,
    ButtonWhite,
    ContactsNew
  },
  setup() {
    const siteData = reactive({
      title: "Webflow | Webmil Web-production",
      description: "We offer services for established businesses and startups, such as digital solutions for iOS, Android, and the web. Contact us: info@webmil.eu",
      keywords: "Webflow",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data() {
    return {
    }
  },
  methods: {
  }  

}
</script>
<style lang="scss" scoped>
@import "../base/styles/global.css";
@import "../base/styles/fonts.scss";

.webflow__contact_us{
   overflow: hidden;
}

.webflow__webmil{
    padding: 80px 0 120px 0;
    @media(max-width: 992px){
        padding: 40px 0 60px 0;
    }
    &__grid{
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-top: 1px solid #F2F2F2;
        
        @media(max-width: 992px){
            grid-template-columns: repeat(1, 1fr);
        }
        
        &-item{
            width: 100%;
            padding: 60px 0 33px 0;
            border-bottom: 1px solid #F2F2F2;
            @media(max-width: 992px){
                padding: 30px 0 16px 0;
            }
            &-text, &-title{
                width: 100%;
                max-width: 462px;
            } 
        }
        &-item:nth-child(odd){
            .webflow__webmil__grid-item-title, .webflow__webmil__grid-item-text{
                margin-left: calc((100vw - 1320px) / 2);
                padding-left: 15px;

                @media(max-width: 1400px){
                    margin-left: calc((100vw - 1140px) / 2);
                }
                @media(max-width: 1200px){
                    margin-left: calc((100vw - 960px) / 2);
                }
                @media(max-width: 992px){
                   margin-left: calc((100vw - 720px) / 2);
                }
                @media(max-width: 768px){
                   margin-left: calc((100vw - 540px) / 2);
                }
                @media(max-width: 575px){
                    margin: 0 auto;
                    padding: 0 12px;
                }
                
            }
        }
        &-item:nth-child(even){
            border-left: 1px solid #F2F2F2;
            .webflow__webmil__grid-item-title, .webflow__webmil__grid-item-text{
                margin-left: 104px;
                max-width: 417px;
                @media(max-width: 992px){
                    padding-left: 15px;
                    max-width: 432px;
                    margin-left: calc((100vw - 720px) / 2);
                }
                @media(max-width: 768px){
                    padding-left: 15px;
                    max-width: 432px;
                    margin-left: calc((100vw - 540px) / 2);
                }
                @media(max-width: 575px){
                    margin: 0 auto;
                    padding: 0 12px;
                    max-width: 444px;
                }
            }

        }
    }
}

.webflow__job{
    padding: 68px 0;
    @media(max-width: 992px){
        padding: 34px 0;
    }
    @media(max-width: 575px){
        padding-bottom: 0;
    }
    &__sub_title:nth-child(1){
        margin: 79px 0 10px 0;
        @media(max-width: 575px){
            margin: 48px 0 10px 0;
        }
    }
    &__sub_title:nth-child(3){
        margin: 60px 0 10px 0;
        @media(max-width: 575px){
            margin: 48px 0 10px 0;
        }
    }
    &__grid{
        width: 100%;
        display: flex;
        column-gap: 61px;
        justify-content: space-between;
        @media(max-width: 992px){
            flex-wrap: wrap;
        }
        &-col1{
            width: 100%;
            max-width: 502px;
            &-text{
                margin: 30px 0 60px 0;
            }
        }
        &-col2{
            width: 100%;
            max-width: 449px;
        }
    }
}

.webflow__explore{
    background: #0D1017;
    padding: 120px 0;
    @media(max-width: 992px){
        padding: 60px 0;
    }
    @media(max-width: 575px){
        padding: 30px 0;
    }
    &__title{
        width: 100%;
        max-width: 502px;
    }
    &__grid{
        width: 100%;
        display: flex;
        column-gap: 61px;
        @media(max-width: 992px){
            flex-wrap: wrap;
        }
        &-col1{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &-text{
                width: 100%;
                max-width: 274px;
            }
            img{
                margin: 120px 0 30px 0;
                width: 100%;
                @media(max-width: 575px){
                    margin: 60px 0 15px 0;
                }
                @media(max-width: 992px){
                    margin: 30px 0 15px 0;
                }
                
            }
        }
        &-col2{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            &-text{
                width: 100%;
                max-width: 286px;
                margin: 26px 0 30px 0;
            }
            img{
                width: 100%;
            }
        }
    }
}

.webflow__why{
    padding: 68px 0;
    @media(max-width: 992px){
        padding: 34px 0;
    }
    &__title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media(max-width: 992px){
            flex-wrap: wrap;
            &-header{
                margin-bottom: 20px;
            }
        }
        &-text{
            width: 100%;
            max-width: 502px;
            margin-top: 0;
            @media(max-width: 575px){
                margin-top: 10px;
            }
        }
    }
}

.webflow__about{
    margin-top: 90px;
    padding: 90px 0;
    @media(max-width: 992px){
        margin-top: 45px;
        padding: 45px 0;
    }
    @media(max-width: 575px){
        padding-bottom: 0;
    }
    
    &__title{
        width: 100%;
        max-width: 919px;
        &-text{
            width: 100%;
            max-width: 450px;
            margin-top: 30px;
            margin-bottom: 64px;
            @media(max-width: 575px){
                margin-bottom: 30px;
            }
        }
    }
    &__img{
        img{
            width: 100%;
        }
    }
}
</style>